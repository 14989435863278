<template>
  <div class="terms">
    <header class="terms__header">
      <h1 class="terms__title">Terms of Service</h1>
    </header>
    <article class="terms__body">
      <p class="terms__effective-date">Effective Date: 18 May 2021</p>

      <p>--</p>

      <p>
        Jeremy Burton<br />
        Developer of Shtum
      </p>
    </article>
  </div>
</template>

<style>
.terms {
}
.terms__header {
  background: var(--brand-primary-50);
}
.terms__title {
  max-width: 64rem;
  margin: 0 auto;
  padding: 3rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--base-text-color);
}
.terms__body {
  max-width: 64rem;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
.terms__effective-date {
  margin-bottom: 1rem;
  font-size: 0.85rem;
  font-weight: 400;
  color: var(--secondary-text-color);
}
.terms__link {
  color: var(--brand-secondary-60);
}
</style>
